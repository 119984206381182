.sign-up {
    align-items: center;
    background-color: #0c1510;
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

.sign-up .image {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.sign-up .left {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    padding: 60px;
    position: relative;
}

.sign-up .back {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
}

.sign-up .vector {
    height: 14px;
    margin-left: -1px;
    position: relative;
    width: 8px;
}

.sign-up .text-wrapper {
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.sign-up .logo {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.sign-up .img {
    object-fit: cover;
    position: relative;
    width: 300px;
    mix-blend-mode: lighten;
}

.sign-up .right {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 60px;
    position: relative;
}

.sign-up .form-outer {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    /* align-items: flex-start; */
    backdrop-filter: blur(40px) brightness(100%);
    background-color: #ffffff4c;
    border: 1px solid;
    border-color: #ffffff66;
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    overflow: hidden;
    padding: 40px;
    position: relative;
}

.sign-up .main-heading {
    align-self: stretch;
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
}

.sign-up .p {
    align-self: stretch;
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    margin-top: 0;

}

.sign-up .form {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 60px 0px 0px;
    position: relative;
    width: 100%;
}

.sign-up .input-instance {
    align-self: stretch !important;
    flex: 0 0 auto !important;
    /* width: 100% !important; */
}

.sign-up .spacer {
    height: 60px;
    position: relative;
    width: 100px;
}

.sign-up .button-instance {
    flex: 0 0 auto !important;
}

.sign-up .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 60px 0px 0px;
    position: relative;
    width: 100%;
}

.sign-up .new-here-create-an {
    align-self: stretch;
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    margin-bottom: 0;
}

.sign-up .span {
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}

.sign-up .text-wrapper-2 {
    text-decoration: underline;
}

.sign-up .forgot-your-password {
    align-self: stretch;
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    margin-bottom: 0;
}

.label {
    height: 26px;
    width: 45px;
}

.label .text-wrapper {
    color: #ffffff;
    font-family: "Roc Grotesk-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    top: 0;
}